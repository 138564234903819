/**
 * Function to sort alphabetically an array of objects by some specific key.
 * 
 * @param {String} property Key of the object to sort.
 */
export function dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    

    return function (a,b) {
        if(sortOrder === -1){
            return b[property].trim().localeCompare(a[property].trim());
        }else{
            return a[property].trim().localeCompare(b[property].trim());
        }        
    }
}
/**
 * @description Is empty object
 */
export const isEmptyObj = (obj) =>  {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false
        }
    }
    return true
}