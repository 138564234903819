export const swaggerDoc = {
    "openapi": "3.0.0",
    "info": {
      "title": "Legaltech",
      "version": "1.0.0"
    },
    "servers": [
      {
        "url": "https://appsearch-legal.mindpeer.com/api/as/v1/engines/dev-legaltech"
      }
    ],
    "components": {
      "securitySchemes": {
        "bearerAuth": {
          "type": "http",
          "scheme": "bearer"
        }
      }
    },
    "tags": [
      {
        "name": "search",
        "description": "Search Section"
      },
    ],
    "paths": {
      "/search": {
        "post": {
          "tags": [
            "search"
          ],
          "consumes": [
            "application/json"
          ],
          "produces": [
            "application/json"
          ],
          "summary": "Search results",
          "description": '# Search results with the query and filters. \n ### The search key will need to set in the top Authorize button for processing the search operations',
          "security": [
            {
              "bearerAuth": []
            }
          ],
          "requestBody": {
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "example": {
                    "query": "",
                    "facets": {
                      "company_names": {
                        "type": "value",
                        "size": 100
                      },
                      "law_firm_names": {
                        "type": "value",
                        "size": 100
                      },
                      "expert_names": {
                        "type": "value",
                        "size": 100
                      },
                      "authors": {
                        "type": "value",
                        "size": 100
                      },
                      "technology_category": {
                        "type": "value",
                        "size": 100
                      },
                      "locations": {
                        "type": "value",
                        "size": 100
                      },
                      "event_type_short_name": {
                        "type": "value",
                        "size": 100
                      },
                      "publication_date": {
                        "type": "range",
                        "ranges": [
                          {
                            "from": "2022-04-14T00:00:00.000Z",
                            "to": "2022-04-14T23:59:59.999Z",
                            "name": "Today"
                          },
                          {
                            "from": "2022-04-13T00:00:00.000Z",
                            "to": "2022-04-13T23:59:59.999Z",
                            "name": "Yesterday"
                          },
                          {
                            "from": "2022-04-06T00:00:00.000Z",
                            "to": "2022-04-14T23:59:59.999Z",
                            "name": "Last Week"
                          },
                          {
                            "from": "2022-03-01T00:00:00.000Z",
                            "to": "2022-03-31T23:59:59.999Z",
                            "name": "Last Month"
                          },
                          {
                            "from": "2022-01-01T00:00:00.000Z",
                            "to": "2022-03-31T23:59:59.999Z",
                            "name": "Last Quarter"
                          },
                          {
                            "from": "2021-10-01T00:00:00.000Z",
                            "to": "2022-03-31T23:59:59.999Z",
                            "name": "Last 6 Months"
                          },
                          {
                            "from": "1000-04-14T09:04:31.781Z",
                            "to": "2022-04-14T23:59:59.999Z",
                            "name": "All"
                          }
                        ]
                      },
                      "brief_date": {
                        "type": "range",
                        "ranges": [
                          {
                            "from": "2022-04-14T00:00:00.000Z",
                            "to": "2022-04-14T23:59:59.999Z",
                            "name": "Today"
                          },
                          {
                            "from": "2022-04-13T00:00:00.000Z",
                            "to": "2022-04-13T23:59:59.999Z",
                            "name": "Yesterday"
                          },
                          {
                            "from": "2022-04-06T00:00:00.000Z",
                            "to": "2022-04-14T23:59:59.999Z",
                            "name": "Last Week"
                          },
                          {
                            "from": "2022-03-01T00:00:00.000Z",
                            "to": "2022-03-31T23:59:59.999Z",
                            "name": "Last Month"
                          },
                          {
                            "from": "2022-01-01T00:00:00.000Z",
                            "to": "2022-03-31T23:59:59.999Z",
                            "name": "Last Quarter"
                          },
                          {
                            "from": "2021-10-01T00:00:00.000Z",
                            "to": "2022-03-31T23:59:59.999Z",
                            "name": "Last 6 Months"
                          },
                          {
                            "from": "1000-04-14T09:04:31.781Z",
                            "to": "2022-04-14T23:59:59.999Z",
                            "name": "All"
                          }
                        ]
                      }
                    },
                    "search_fields": {
                      "title": {},
                      "company_names": {},
                      "locations": {},
                      "law_firm_names": {}
                    },
                    "result_fields": {
                      "title": {
                        "snippet": {
                          "size": 500,
                          "fallback": true
                        }
                      },
                      "full_text": {
                        "snippet": {
                          "size": 1000,
                          "fallback": true
                        }
                      },
                      "brief_edition": {
                        "snippet": {
                          "size": 75,
                          "fallback": true
                        }
                      },
                      "brief_date": {
                        "raw": {},
                        "snippet": {
                          "size": 75,
                          "fallback": true
                        }
                      },
                      "url": {
                        "snippet": {
                          "fallback": true
                        }
                      },
                      "authors": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "authors_data": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "experts_data": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "expert_names": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "company_names": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "law_firm_names": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "technology_category": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "keyword_tags": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "event_types": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "event_type_short_name": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "event_type_keywords": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "publication_date": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      },
                      "locations": {
                        "raw": {},
                        "snippet": {
                          "size": 50,
                          "fallback": true
                        }
                      }
                    },
                    "filters": {},
                    "sort": {
                      "publication_date": "desc"
                    },
                    "page": {
                      "size": 10,
                      "current": 1
                    }
                  }
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Successful Response",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "meta": {
                        "type": "object"
                      },
                      "results": {
                        "type": "object",
                      }
                    }
                  }
                }
              }
            },
            "401": {
              "description": "Unauthorized",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            },
            "400": {
              "description": "Bad Request",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            },
            "500": {
              "description": "Internal Server Error",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            }
          }
        }
      },
      "/query_suggestion": {
        "post": {
          "tags": [
            "query_suggestion"
          ],
          "consumes": [
            "application/json"
          ],
          "produces": [
            "application/json"
          ],
          "summary": "Search query suggestions",
          "description": '# Search auto complete query suggestions. \n ### The search key will need to set in the top Authorize button for processing the search operations',
          "security": [
            {
              "bearerAuth": []
            }
          ],
          "requestBody": {
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "example": {
                    "query": "mike",
                    "types": {
                      "documents": {
                        "fields": [
                          "title",
                          "company_names",
                          "locations",
                          "law_firm_names"
                        ]
                      }
                    },
                    "size": 5
                  }
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Successful Response",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "meta": {
                        "type": "object"
                      },
                      "results": {
                        "type": "object",
                      }
                    }
                  }
                }
              }
            },
            "401": {
              "description": "Unauthorized",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            },
            "400": {
              "description": "Bad Request",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            },
            "500": {
              "description": "Internal Server Error",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }