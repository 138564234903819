
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import { CONSTANTS } from '../../constants'
import { configDateObj } from '../utils/date'
const {
  SEARCH_KEY,
  ENGINE_NAME,
  ENDPOINT_BASE
} = CONSTANTS

const connector = new AppSearchAPIConnector({
  searchKey: SEARCH_KEY,
  engineName: ENGINE_NAME,
  endpointBase: ENDPOINT_BASE
})
const {
  startDay,
  endDay,
  yestStartDay,
  yestEndDay,
  lastWeekStartDay,
  lastWeekEndDay,
  lastMonthStartDay,
  lastMonthEndDay,
  startQuarterDay,
  endQuarterDay,
  lastSixMonthStart,
  lastSixMonthEnd,
  initialStartOfYear
} = configDateObj
/**
 * @description Configuration
 */
export const configurationOptions = {
  apiConnector: connector,
  autocompleteQuery: {
    suggestions: {
      types: {
        documents: {
          fields: ['title', 'company_names', 'locations', 'law_firm_names']
        }
      },
      size: 5
    }
  },
  initialState: {
    sortField: 'publication_date',
    sortDirection: 'desc',
    resultsPerPage: 10,
  },
  searchQuery: {
    disjunctiveFacets: ["company_names", "law_firm_names","expert_names",
    "authors", "technology_category", "locations", "event_type_short_name","publication_date","brief_date"
  ],
    search_fields: {
      title: {},
      company_names: {},
      locations: {},
      law_firm_names: {}
    },
    result_fields: {
      title: {
        snippet: {
          size: 500,
          fallback: true
        }
      },
      full_text: {
        snippet: {
          size: 1000,
          fallback: true
        }
      },
      brief_edition: {
        snippet: {
          size: 75,
          fallback: true
        }
      },
      brief_date: {
        raw: {},
        snippet: {
          size: 75,
          fallback: true
        }
      },
      url: {
        snippet: {
          fallback: true
        }
      },
      authors: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      authors_data: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      experts_data: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      expert_names: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      company_names: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      law_firm_names: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      technology_category: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      keyword_tags: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      event_types: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      event_type_short_name: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      event_type_keywords: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      publication_date: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      locations: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      
    },
    facets: {
      company_names: {
        type: 'value', size: 100
      },
      law_firm_names: {
        type: 'value', size: 100
      },
      expert_names: {
        type: 'value', size: 100
      },
      authors: {
        type: 'value', size: 100
      },
      technology_category: {
        type: 'value', size: 100
      },
      locations: {
        type: 'value', size: 100
      },
      event_type_short_name: {
        type: 'value', size: 100
      },
      publication_date: {
        type: 'range',
        ranges: [
          { from: startDay, to: endDay, name: 'Today' },
          { from: yestStartDay, to: yestEndDay, name: 'Yesterday' },
          { from: lastWeekStartDay, to: lastWeekEndDay, name: 'Last Week' },
          { from: lastMonthStartDay, to: lastMonthEndDay, name: 'Last Month' },
          { from: startQuarterDay, to: endQuarterDay, name: 'Last Quarter' },
          { from: lastSixMonthStart, to: lastSixMonthEnd, name: 'Last 6 Months' },
          { from: initialStartOfYear, to: endDay, name: 'All' }
        ]
      },
      brief_date: {
        type: 'range',
        ranges: [
          { from: startDay, to: endDay, name: 'Today' },
          { from: yestStartDay, to: yestEndDay, name: 'Yesterday' },
          { from: lastWeekStartDay, to: lastWeekEndDay, name: 'Last Week' },
          { from: lastMonthStartDay, to: lastMonthEndDay, name: 'Last Month' },
          { from: startQuarterDay, to: endQuarterDay, name: 'Last Quarter' },
          { from: lastSixMonthStart, to: lastSixMonthEnd, name: 'Last 6 Months' },
          { from: initialStartOfYear, to: endDay, name: 'All' }
        ]
      }
    }

  },
  alwaysSearchOnInitialLoad: true
}
