
import React, { Component } from 'react'
import ResultCard from '../components/result/result'
import {
    Results,
} from '@elastic/react-search-ui'
import { CSVLink } from "react-csv"
/**
 * @description Result container component
 */
class Result extends Component {
    constructor (props) {
        super(props)
        this.state = {
            selectedArticles: [],
            csvData: []
        }
        this.headers = [
            { label: "Id", key: "id" },
            { label: "Title", key: "title" },
            { label: "Url", key: "url" },
            { label: "Description", key: "description" },
            { label: "Companies", key: "company_names" },
            { label: "Law Firms", key: "law_firm_names" },
            { label: "Experts", key: "expert_names" },
            { label: "Technology", key: "technology_category" },
            { label: "Locations", key: "locations" },
            { label: "Event Type", key: "event_type_short_name" },
            { label: "Event Type Keywords", key: "event_type_keywords" },
            { label: "Authors", key: "authors" },
            { label: "Keywords/Tags", key: "keywords" },
            { label: "Brief Date", key: "brief_date" },
            { label: "Published Date", key: "published_date" },

        ]
        
    }
    /**
     * @description when component will receive props
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        this.setState({selectedArticles: [], csvData: []})
    }
/**
* @description reset selection
*/
resetSelection = () => {
    this.setState({selectedArticles: [], csvData: []})
}
/** 
 * @description toggle selection
 */
toggleSelection = (val, result) => {
    const selectedArr = this.state.selectedArticles
    let date = ''
    let briefDate = ''
    const selectedCSVArr = this.state.csvData
    let index = selectedArr.indexOf(val)
    let csvDataIndex = selectedCSVArr.findIndex(x => x.id === val)
    if(index !== -1) {
        selectedArr.splice(index, 1);
        selectedCSVArr.splice(csvDataIndex, 1)

    }else {
        if (result.publication_date.raw) {
            const dateValue = result.publication_date.raw
            date = new Date(dateValue)
        }
        if (result.brief_date.raw) {
        const briefDateValue = result.brief_date.raw
        briefDate = new Date(briefDateValue)
        }
        selectedArr.push(val)
        selectedCSVArr.push({
            id: result['id']['raw'] || '',
            title: this.filterStr(result.title.snippet) || '',
            url : result.url.snippet || '',
            description: this.filterStr(result.full_text.snippet) || '',
            company_names: result.company_names.raw  &&  result.company_names.raw.length > 0 ? 
            result.company_names.raw.toString() : '',
            law_firm_names: result.law_firm_names.raw  &&  result.law_firm_names.raw.length > 0 ? 
            result.law_firm_names.raw.toString() : '',
            expert_names: result.experts_data.raw  &&  result.experts_data.raw.length > 0 ? 
            result.experts_data.raw.map((r) => { r = JSON.parse(r); return r.name }) : '',
            technology_category: result.technology_category.raw  &&  result.technology_category.raw.length > 0 ? 
            result.technology_category.raw.toString() : '',
            locations: result.locations.raw  &&  result.locations.raw.length > 0 ? 
            result.locations.raw.toString() : '',
            event_type_short_name: result.event_type_short_name.raw  &&  result.event_type_short_name.raw.length > 0 ? 
            result.event_type_short_name.raw.toString() : '',
            event_type_keywords: result.event_type_keywords.raw  &&  result.event_type_keywords.raw.length > 0 ? 
            result.event_type_keywords.raw.toString() : '',
            authors: result.authors_data.raw  &&  result.authors_data.raw.length > 0 ? 
            result.authors_data.raw.toString() : '',
            keywords: result.keyword_tags.raw  &&  result.keyword_tags.raw.length > 0 ? 
            result.keyword_tags.raw.toString() : '',
            brief_date: briefDate ? 
            briefDate.getFullYear() + '-' + (briefDate.getMonth() + 1) + '-' + briefDate.getDate() : '',
            published_date: date ? 
                date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() : '',
        })
    }  
    
    this.setState({selectedArticles: selectedArr, csvData: selectedCSVArr})
}
/** 
 * @description Master toggle selection
 */
masterToggle = (results) => {
    const arr = []
    const csvArr = []
    if(this.isAllSelected(results)) {
        this.setState({selectedArticles: [], csvData: []})
    } else {
    results.forEach(result => {
        let date = ''
        let briefDate = ''
        arr.push(result['id']['raw']) 
        if (result.publication_date.raw) {
            const dateValue = result.publication_date.raw
            date = new Date(dateValue)
        }
        if (result.brief_date.raw) {
        const briefDateValue = result.brief_date.raw
        briefDate = new Date(briefDateValue)
        }
        csvArr.push({
            id: result['id']['raw'] || '',
            title: this.filterStr(result.title.snippet) || '',
            url : result.url.snippet || '',
            description: this.filterStr(result.full_text.snippet) || '',
            company_names: result.company_names.raw  &&  result.company_names.raw.length > 0 ? 
            result.company_names.raw.toString() : '',
            law_firm_names: result.law_firm_names.raw  &&  result.law_firm_names.raw.length > 0 ? 
            result.law_firm_names.raw.toString() : '',
            expert_names: result.experts_data.raw  &&  result.experts_data.raw.length > 0 ? 
            result.experts_data.raw.map((r) => { r = JSON.parse(r); return r.name })  : '',
            technology_category: result.technology_category.raw  &&  result.technology_category.raw.length > 0 ? 
            result.technology_category.raw.toString() : '',
            locations: result.locations.raw  &&  result.locations.raw.length > 0 ? 
            result.locations.raw.toString() : '',
            event_type_short_name: result.event_type_short_name.raw  &&  result.event_type_short_name.raw.length > 0 ? 
            result.event_type_short_name.raw.toString() : '',
            event_type_keywords: result.event_type_keywords.raw  &&  result.event_type_keywords.raw.length > 0 ? 
            result.event_type_keywords.raw.toString() : '',
            authors: result.authors_data.raw  &&  result.authors_data.raw.length > 0 ? 
            result.authors_data.raw.toString() : '',
            keywords: result.keyword_tags.raw  &&  result.keyword_tags.raw.length > 0 ? 
            result.keyword_tags.raw.toString() : '',
            brief_date: briefDate ? 
            briefDate.getFullYear() + '-' + (briefDate.getMonth() + 1) + '-' + briefDate.getDate() : '',
            published_date: date ? 
                date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() : '',
        })
    });
    this.setState({selectedArticles: arr, csvData:  csvArr})
    }
    
}
    
    /**
     * @description Check for is selected
     */
    isAllSelected = (results) => {
        return results.length === this.state.selectedArticles.length
    }
    /**
     * @description filter string
     */
    filterStr = (str) => {
        return str.replace(/<[^>]+>/g, '')
    }
    render () {
        const { selectedArticles } = this.state
        const { results } = this.props

        return (
            <>
            <div className="topHeader">
            {
                selectedArticles.length > 0 && (
                <div className="leftHeader_item">
                    <div className="selected_item">
                        <span className="icon-minus" ></span> 
                        <span>{selectedArticles.length} Article(s) Selected</span>
                        </div>
                        <div className="select_all_item cursor" onClick={() => {this.masterToggle(results)}}>
                        {selectedArticles.length === results.length ? 'Un-Select All' : 'Select All'}
                    </div>
                </div>
                )}
            {
                    selectedArticles.length > 0 && (
                        <div className="rightHeader_item">
                            <div className="selected_moreOption">
                                    <div>
                                        <CSVLink
                                            data={this.state.csvData}
                                            filename={"data.csv"}
                                            className="btn btn-primary"
                                            target="_blank"
                                            headers={this.headers}
                                            >
                                            <span className="icon-folder-alt"></span>Download CSV
                                        </CSVLink>
                                        </div>
                            </div>                                  
                        </div>
                    )   
                    }
                </div>
            <Results resultView={(props) => (
                <ResultCard 
                selectedArticles={selectedArticles}
                resultProps={props}
                toggle={this.toggleSelection}
                masterToggle={this.masterToggle}
                resetSelection={this.resetSelection}
                />
            )}
            />
                
            </> 

        )
    }
}
export default Result
