import React from "react";
import {dynamicSort} from '../../utils/helpers'
import { ClickAwayListener } from '@material-ui/core';

 function getFilterValueDisplay(filterValue) {
    if (filterValue === undefined || filterValue === null) return "";
    if (filterValue.hasOwnProperty("name")) return filterValue.name;
    return String(filterValue);
  }
function MultiCheckboxFacetT({
  label,
  onMoreClick,
  onRemove,
  onSelect,
  options,
  showMore,
  showSearch,
  onSearch,
  searchPlaceholder,
  sortFlag= false,
}) {
  const node = React.useRef();
  const [sortVal, setSorting] = React.useState('publication'); 
  const [open, setOpen] = React.useState(false);

  const handleClick1 = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    };
  }, [])
  const handleClick = (e) => {
    //
  }
  // const [sortBox, setSortBox] = React.useState(false); 
  if(sortFlag) {
    if (sortVal === 'publication') {
      options.sort((n1,n2) => n2.count - n1.count );
    } else {
      options.sort(dynamicSort("value"))
    }
    
  }
  
  return (
    <fieldset className={"sui-facet"}>
      <div className="HeadingOuter" >
        <legend className="sui-facet__title">{label}</legend> 
  
        <ClickAwayListener onClickAway={handleClickAway}>
      <div>
      <div className="sui-facet__icon cursor">
        <i className="fas fa-sort-amount-up-alt" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick1}></i>
      </div>
        {open ? (
          <div className="dropdown-box" ref={node}>
          <h1>Sort By</h1>
          <ul>
              {/* eslint-disable-next-line */}
              <li><a onClick={(e) => {setSorting('name')}} className={sortVal === 'name' ?  'clicked cursor' : 'cursor'}>Alphabetically</a></li>
              {/* eslint-disable-next-line */}
              <li><a onClick={(e) => {setSorting('publication')}}  className={sortVal === 'publication' ?  'clicked cursor' : 'cursor'}>Number of Publications</a></li>
          </ul>
        </div>
        ) : null}
      </div>
    </ClickAwayListener>
      </div>

      {showSearch && (
        <div className="sui-facet-search">
          <input
            className="sui-facet-search__text-input"
            type="search"
            placeholder={searchPlaceholder || "Search"}
            onChange={e => {
              onSearch(e.target.value);
            }}
          />
        </div>
      )}
      <div className="sui-multi-checkbox-facet">
        {options.length < 1 && <div>No matching options</div>}
        
        {options.map(option => {
          const checked = option.selected;
          return (
            <label
              key={`${getFilterValueDisplay(option.value)}`}
              htmlFor={`example_facet_${label}${getFilterValueDisplay(
                option.value
              )}`}
              className="sui-multi-checkbox-facet__option-label"
            >
              <div className="sui-multi-checkbox-facet__option-input-wrapper">
                <input
                  id={`example_facet_${label}${getFilterValueDisplay(
                    option.value
                  )}`}
                  type="checkbox"
                  className="sui-multi-checkbox-facet__checkbox"
                  checked={checked}
                  onChange={() =>
                    checked ? onRemove(option.value) : onSelect(option.value)
                  }
                />
                <span className="sui-multi-checkbox-facet__input-text">
                  {getFilterValueDisplay(option.value)}
                </span>
              </div>
              <span className="sui-multi-checkbox-facet__option-count">
                {option.count.toLocaleString("en")}
              </span>
            </label>
          );
        })}
      </div>

      {showMore && (
        <button
          type="button"
          className="sui-facet-view-more"
          onClick={onMoreClick}
          aria-label="Show more options"
        >
          + More
        </button>
      )}
    </fieldset>
  );
}
export default MultiCheckboxFacetT;