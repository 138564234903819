import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import './result.css'
import PropTypes from 'prop-types'
import marked from 'marked'
/**
 * @description Result card component to display results
 */
class ResultCard extends Component {
  render () {
    const {resultProps, selectedArticles, toggle} = this.props
    const { key, result } = resultProps
    const resultId = result['id']['raw']
    let date = ''
    let briefDate = ''
    if (result.publication_date.raw) {
      const dateValue = result.publication_date.raw
      date = new Date(dateValue)
    }
    if (result.brief_date.raw) {
      const briefDateValue = result.brief_date.raw
      briefDate = new Date(briefDateValue)
    }
    let fullTextStr = ''
    if(result.full_text.snippet) {
      fullTextStr = marked(result.full_text.snippet ? result.full_text.snippet: '')
      fullTextStr = fullTextStr.split("<a href").join("<a target='_blank' href")
    }
    
    let prevMetaDataFlag = false
    return (
      <>
        <div key={key} className="outerCard">
          <div className={selectedArticles.indexOf(resultId) !== -1 ? "selectionRadio" : "selectionRadio selectionRadioBefore hoverClass"} onClick={(e) => {
            e.preventDefault(); 
            toggle(resultId, result)
            }}>
            <span className="far fa-circle"></span>
            {selectedArticles.indexOf(resultId) !== -1 ?  (
              <span className="far fa-check-circle selectedItem"></span>
            ) : ''}
          </div>
          <div className="listing-box" >
            <div className="listing-box-header">
              <div  className = {result.url.snippet ? 'listing-box-title cursor' : 'listing-box-title disabled'}  onClick={(e) => {
                  e.preventDefault();
                  return window.open(
                    result.url.snippet,
                    '_blank' // <- This is what makes it open in a new window.
                  );
                }}>
                <div className="author-outer">
                {
                    result.authors_data.raw && result.authors_data.raw.length > 0 ? (
                      <>
                        {result.authors_data.raw.map((authorData, index) => {
                          const author = JSON.parse(authorData)
                          return (
                            <div key={index}>
                              <span className="author" title={author.name} >
                                <a href={author.url ? author.url : '#'} className = {author.url ? '' : 'disabled'}
                                target="_blank" rel="noopener noreferrer"><i className="fa fa-link"></i>{author.name}{index + 1 === result.authors.raw.length ? '': ','}</a></span>
                            </div>
                          )
                          })}
                      </>
                    ) : ''
                }
                </div>
                <div className="listing-box-heading">
                  <h2>{ReactHtmlParser(marked(result.title.snippet || ''))}</h2>
                  <div className="listing-box-more">
                    <a href={result.url.snippet} target="_blank" rel="noopener noreferrer">
                      <span className={result.url.snippet ? "icon-arrow-right-circle" : "icon-arrow-right-circle grad-out disabled"}></span></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="listing-box-header-description">
              <div className="listing-box-title">
                {ReactHtmlParser(fullTextStr)}
                </div>
            </div>

            <div className="listing-box-tags">

              {result.company_names.raw  &&  result.company_names.raw.length > 0 ? (
                <>
                {prevMetaDataFlag = true}
                  <div className="tags-list" >
                    <span className="tag-list-icon" title="Companies"><i className="fas fa-building" ></i></span>
                    <div className="tags-listing">
                      {result.company_names.raw.map((industry, index) => (
                          <span className="tags-listing-item" title={industry.trim()} key={index}>
                            {industry.trim()}{index + 1 === result.company_names.raw.length ? '': ','}</span>
                        ))}
                    </div>
                  </div>
                  </>
              ) : '' }
              {result.law_firm_names.raw  &&  result.law_firm_names.raw.length > 0 ? (
                <>
                
                  {prevMetaDataFlag === true && (
                    <div className="divider-tags"></div>
                  )}
                { prevMetaDataFlag = true }
                <div className="tags-list">
                  <span className="tag-list-icon"  title="Law Firms"><i className="fas fa-balance-scale-right"></i></span>
                  <div className="tags-listing">
                    {result.law_firm_names.raw.map((topic, index) => (
                        <span className="tags-listing-item" title={topic.trim()}  key={index}>{topic.trim()}{index + 1 === result.law_firm_names.raw.length ? '': ','}</span>
                      ))}
                  </div>
                </div>
                </>
              ) :  '' }
              {result.experts_data.raw  &&  result.experts_data.raw.length > 0 ? (
                <>
                
                
                {prevMetaDataFlag === true && (
                    <div className="divider-tags"></div>
                  )}
                { prevMetaDataFlag = true }
                <div className="tags-list">
                  <span className="tag-list-icon" title="Experts"><i className="fas fa-user-tie"></i></span>
                  <div className="tags-listing">
                    {result.experts_data.raw.map((expertData, index) => {
                      const expert = JSON.parse(expertData)
                      return(
                        <span key={index}>
                          <span className="tags-listing-item" title={expert.name} key={index}>{expert.name}{index + 1 === result.experts_data.raw.length ? '': ','}</span>
                        </span>
                      )})}
                  </div>
                </div>
                </>
              ) : ''}
              {result.technology_category && result.technology_category.raw  &&  result.technology_category.raw.length > 0 ? (
                <>
                
                {prevMetaDataFlag === true && (
                    <div className="divider-tags"></div>
                  )}
                { prevMetaDataFlag = true }
                <div className="tags-list">
                  <span className="tag-list-icon" title="Technology"><i className="fa fa-microchip"></i></span>
                  <div className="tags-listing">
                    {result.technology_category.raw.map((techName, index) => {
                      return(
                        <span key={index}>
                          <span className="tags-listing-item" title={techName.trim()} key={index}>{techName.trim()}{index + 1 === result.technology_category.raw.length ? '': ','}</span>
                        </span>
                      )})}
                  </div>
                </div>
                </>
              ) : '' }
              {result.event_type_short_name.raw  &&  result.event_type_short_name.raw.length > 0 ? (
                <>
                
                {prevMetaDataFlag === true && (
                    <div className="divider-tags"></div>
                  )}
                { prevMetaDataFlag = true }
                <div className="tags-list">
                  <span className="tag-list-icon" title="Event Type"><i className="fas fa-bullhorn"></i></span>
                  <div className="tags-listing">
                    {result.event_type_short_name.raw.map((shortName, index) => {
                      return(
                        <span key={index}>
                          <span className="tags-listing-item" title={shortName.trim()}  key={index}>{shortName.trim()}{index + 1 === result.event_type_short_name.raw.length ? '': ','}</span>
                        </span>
                      )})}
                  </div>
                </div>
                </>
              ) : '' }
            </div>
            <div className="listing-footer">
              {
                result.locations.raw && result.locations.raw.length > 0 ? (
                  <>
                  <div className="location">
                  Locations&nbsp;:&nbsp;
                  <strong>
                  {
                    result.locations.raw !== null
                    ? (result.locations.raw.map((location, index) => (
                      <span key={index}> {location}{result.locations.raw.length !== index + 1 && ' , ' }</span>
                    ))) : ''}
                </strong>
                </div>
                <div className="divider-tags"></div>
                  </>
                ):
                ''
              }
              
              
              <div className="publish">Published Date&nbsp;:&nbsp;  <strong>{date ? 
              date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() : ''}</strong></div>
              <div className="divider-tags"></div>
              <div className="publish">Brief Date&nbsp;:&nbsp;  <strong>{briefDate ? 
              briefDate.getFullYear() + '-' + (briefDate.getMonth() + 1) + '-' + briefDate.getDate() : ''}</strong></div>
            </div>
            <div className="listing-footer footer-last">
              {
                result.keyword_tags.raw && result.keyword_tags.raw.length > 0 ? (
                  <>
                  <div className="location">
                  Keywords/Tags&nbsp;:&nbsp;
                  <strong>
                  {
                    result.keyword_tags.raw !== null
                    ? (result.keyword_tags.raw.map((keyword, index) => (
                      <span key={index}> {keyword}{result.keyword_tags.raw.length !== index + 1 && ' , ' }</span>
                    ))) : ''}
                </strong>
                </div>
                  </>
                ):
                ''
              }
            </div>

          </div>
        </div>
      </>
    )
  }
}
/** ResultCard component prop types */
ResultCard.propTypes = {
  props: PropTypes.object

}

export default ResultCard