export const CONSTANTS = {
  SEARCH_KEY: 'search-w1mau52jjebi1yar1b1ftnj9',
  ENGINE_NAME: 'dev-legaltech',
  ENDPOINT_BASE: 'https://appsearch-legal.mindpeer.com',
}

export const facetFields = {
  'company_names': 'Companies',
  "technology_category": "Technology",
  "event_type_short_name": "Event Type",
  "publication_date": "Publication Date",
  "brief_date": "Brief Date",
  "law_firm_names": "Law Firms",
  "expert_names": "Experts"
}