
import React, { Component } from 'react'
import  MultiCheckboxFacetT  from '../components/main/custom-facets'
import {
  Facet,
} from '@elastic/react-search-ui'
/**
 * @description facet container component
 */
class FacetContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loadData: false,
      loader: true,
      error: false,
      windowDimensions: { width: 0, height: 0 },
      sortTypeVal: '',
    }
  }

  /** @desc When component will setup */
  componentDidMount () {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  /**
   * @description When component will un-mount
   */
  componentWillUnmount() {
    this.setState({sortTypeVal: ''})
    window.removeEventListener('resize', this.updateDimensions);
  }
  /**
   * @description update sort
   */
  updateSort = (val) => {
    this.setState({sortTypeVal: val})
  }
  /**
   * @description Update window dimensions
   */
  updateDimensions = () => {
    this.setState({ windowDimensions: {width: window.innerWidth, height: window.innerHeight }});
  }
  render () {
    const { sortTypeVal } = this.state
    return (
        <>
        <Facet field="technology_category" label="Technology" isFilterable={true} show={100} filterType="any"
          view={(props) => <MultiCheckboxFacetT {...props} searchPlaceholder="Filter technology"  sortFlag={true} sortTypeVal={sortTypeVal} updateSort={this.updateSort}/>}/>
          <Facet field="company_names" label="Companies" isFilterable={true} show={100} filterType="any"
          view={(props) => {
          return (<MultiCheckboxFacetT {...props} searchPlaceholder="Filter companies" sortFlag={true}  sortTypeVal={sortTypeVal} updateSort={this.updateSort}/>)}}/>
          <Facet field="law_firm_names" label="Law Firms" isFilterable={true} show={100}  filterType="any"
          view={(props) => <MultiCheckboxFacetT {...props} searchPlaceholder="Filter law firms"  sortFlag={true} sortTypeVal={sortTypeVal} updateSort={this.updateSort}/>}/>
          <Facet field="expert_names" label="Experts" isFilterable={true} show={100} filterType="any"
          view={(props) => <MultiCheckboxFacetT {...props} searchPlaceholder="Filter experts"  sortFlag={true} sortTypeVal={sortTypeVal} updateSort={this.updateSort}/>}/>
          <Facet field="authors" label="Authors" isFilterable={true} show={100} filterType="any"
          view={(props) => <MultiCheckboxFacetT {...props} searchPlaceholder="Filter authors"  sortFlag={true} sortTypeVal={sortTypeVal} updateSort={this.updateSort}/> }/>
          <Facet field="locations" label="Locations" isFilterable={true} show={100} filterType="any"
          view={(props) => <MultiCheckboxFacetT {...props} searchPlaceholder="Filter locations"  sortFlag={true} sortTypeVal={sortTypeVal} updateSort={this.updateSort}/>}/>
          <Facet field="event_type_short_name" label="Event Type" isFilterable={true} show={100} filterType="any"
          view={(props) => <MultiCheckboxFacetT {...props} searchPlaceholder="Filter event type"  sortFlag={true} sortTypeVal={sortTypeVal} updateSort={this.updateSort}/>}/>
          <Facet field="publication_date" label="Published Date" isFilterable={true} show={100} filterType="any"
          view={(props) => <MultiCheckboxFacetT {...props} searchPlaceholder="Filter published date" sortTypeVal={sortTypeVal} updateSort={this.updateSort}/> }/>
          <Facet field="brief_date" label="Brief Date" isFilterable={true} show={100} filterType="any"
          view={(props) => <MultiCheckboxFacetT {...props} searchPlaceholder="Filter brief date" sortTypeVal={sortTypeVal} updateSort={this.updateSort}/>}/>
        </> 

    )
  }
}
export default FacetContainer
