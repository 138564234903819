
import React, { Component } from 'react'
import {
  PagingInfo,
  Paging,
  Facet,
  SearchBox,
  Sorting,
  SearchProvider,
  WithSearch
} from '@elastic/react-search-ui'
import PropTypes from 'prop-types'
import { Layout } from '@elastic/react-search-ui-views'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import Result from '../../containers/result'
import './main.css'
import { facetFields } from '../../../constants'
import { configurationOptions } from '../../config/search-config'
import FacetContainer from '../../containers/facet'
/**
 * @description Main component for search and filter operations using elastic search
 */
class MainTemplate extends Component {
  render () {
    const subscribed = true
    const { windowDimensions } = this.props
    const { width } = windowDimensions
    return (
      <>
        <SearchProvider config={configurationOptions}>
          <WithSearch mapContextToProps={({
            results, isLoading, searchTerm,
            setSearchTerm, reset, clearFilters, error, filters, current, removeFilter
          }) =>
            ({
              results,
              isLoading,
              searchTerm,
              setSearchTerm,
              reset,
              error,
              clearFilters,
              filters,
              current,
              removeFilter
            })}>
            {
              ({
                results, isLoading, searchTerm, setSearchTerm,
                reset, clearFilters, error, filters, current, removeFilter
              }) => { 
                return (
                  <div className={width < 991 ? 'App search-filter-mob' : 'App'}>
                    {results && (
                      <Layout
                        className="main-container"
                        bodyContent={
                          <div className="bodyContainer">
                            <div className="searchBox-outer">
                              {subscribed && (
                                <SearchBox 
                                autocompleteSuggestions={true}  debounceLength={0} className="custom-search"
                                />
                              )}
                              {!subscribed && (
                                <SearchBox inputProps={{ placeholder: 'Search locked' }}
                                  autocompleteSuggestions={false} className="custom-search disabled"
                                  onSubmit={() => {
                                    return false
                                  }}
                                />
                              )}
                            </div>

                            <div className="search_clear_outer">
                              {searchTerm && (<div className="clear_search">
                                <span onClick={() => { return setSearchTerm('') }} className="pointer main_searchclear_box">
                                  <strong>Search:</strong>
                                  <div>{searchTerm}</div>
                                  <span>&#10005;</span>
                                </span>
                              </div>)}
                              {(filters.length > 0) && (
                                filters.map((filter, index) => (
                                  <div className = "clear_filter" key={index}>
                                    <span onClick={() => { return removeFilter(filter.field) }} className="pointer main_searchclear_box">
                                      <strong>{facetFields[filter.field]}:</strong> {filter.values.map((val, index) => (
                                        <div key={index}>
                                          {typeof val === 'object' ? val.name : val} {filter.values.length !== index + 1 && ' , ' }
                                        </div>))}
                                      <span>&#10005;</span>
                                    </span>
                                  </div>
                                ))
                              )
                              }
                              {(searchTerm || filters.length > 0) && (
                                <div className = "clear_all">
                                  <span onClick={() => { reset(); return setSearchTerm('') }} className="pointer main_searchclear_box"><strong>Clear All</strong></span>
                                </div>
                              )
                              }
                            </div>
                            <div className="paging-sorting-outer">
                              <PagingInfo className="custom-page-info"/>
                              {subscribed && (
                                <Sorting
                                  className="custom-sorting"
                                  label={'Sort by'}
                                  sortOptions={[
                                    {
                                      name: 'Published Date',
                                      value: 'publication_date',
                                      direction: 'desc'
                                    },
                                    {
                                      name: 'Brief Date',
                                      value: 'brief_date',
                                      direction: 'desc'
                                    }
                                  ]}
                                />
                              )}
                            </div>
                            {!error && isLoading && (<div>Loading....</div>)}
                            {error && (<div>Something went wrong.</div>)}
                            {results.length === 0 && !isLoading && <div>No Results Found</div>}
                            { results.length > 0 && (
                              <Result results={results}/>
                              // <Results resultView={(props) => (
                              //   <Result resultProps={props}/>
                              // )}
                              // />
                              )
                            }
                          </div>
                        }
                        sideContent={
                          <div>
                            {subscribed && (
                              <>
                              <FacetContainer />
                                
                              </>
                            )}
                            {!subscribed && (
                              <>
                                <div>Filters locked</div>
                                <Facet field="topics" label="Topics" isFilterable={false}
                                  filters={[]} addFilter={() => { return true }} className='disabled' />
                                <Facet field="published_by" label="Published By" isFilterable={false}
                                  filters={[]} addFilter={() => { return true }} className='disabled'/>
                                <Facet field="locations" label="Location" isFilterable={false}
                                  filters={[]} addFilter={() => { return true }} className='disabled'/>
                                <Facet field="industries" label="Industry" isFilterable={false}
                                  filters={[]} addFilter={() => { return true }} className='disabled'/>
                                <Facet field="practice_groups" label="Practice Groups" isFilterable={false}
                                  filters={[]} addFilter={() => { return true }} className='disabled'/>
                                <Facet field="published_on" label="Published On" isFilterable={false}
                                  filters={[]} addFilter={() => { return true }} className='disabled'/>
                              </>

                            )}

                          </div>
                        }
                        bodyFooter={<Paging/>}
                      />
                    )}
                  </div>
                )
              }}
          </WithSearch>
        </SearchProvider>
      </>

    )
  }
}
/** MainTemplate component prop types */
MainTemplate.propTypes = {
  props: PropTypes.object,
  windowDimensions: PropTypes.object

}
export default MainTemplate
